// Imports
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/home/Index.vue'),
      children: [
        {
          path: '',
          name: 'Inicio',
          component: () => import('@/views/home/Index.vue'),
        },
        {
          path: 'sobre-nosotros',
          name: 'Sobre Nosotros',
          component: () => import('@/views/about/Index.vue'),
          meta: { src: require('@/assets/about.jpg') },
        },
        {
          path: 'productos',
          name: 'Productos',
          component: () => import('@/views/producto/Index.vue'),
        },
        {
          path: 'productos/pudin',
          name: 'Pudin',
          component: () => import('@/views/sections/Pudin.vue'),
        },
        {
          path: 'productos/txangurro',
          name: 'Txangurro',
          component: () => import('@/views/sections/Txangurro.vue'),
        },
        {
          path: 'productos/gourmet',
          name: 'Gourmet',
          component: () => import('@/views/sections/Gourmet.vue'),
        },
        {
          path: 'productos/pinchos',
          name: 'Pinchos',
          component: () => import('@/views/sections/Pinchos.vue'),
        },
        {
          path: 'productos/conservas',
          name: 'Conservas',
          component: () => import('@/views/sections/Conservas.vue'),
        },
        {
          path: 'contacto',
          name: 'Contacto',
          component: () => import('@/views/contact-us/Index.vue'),
          meta: { src: require('@/assets/contact.jpg') },
        },
        {
          path: 'politica-de-privacidad',
          name: 'PoliticaDePrivacidad',
          component: () => import('@/views/sections/PoliticaPriv.vue'),
        },
        {
          path: 'politica-de-cookies',
          name: 'PoliticaDeCookies',
          component: () => import('@/views/sections/PoliticaCookies.vue'),
        },
        {
          path: 'aviso-legal',
          name: 'AvisoLegal',
          component: () => import('@/views/sections/AvisoLegal.vue'),
        },
        {
          path: '404',
          name: 'FourOhFour',
          component: () => import('@/views/404/Index.vue'),
        },
        {
          path: '*',
          name: 'FourOhFourX',
          component: () => import('@/views/404/Index.vue'),
        },
      ],
    },
    {
      path: '*',
      name: 'FourOhFourX',
      component: () => import('@/views/404/Index.vue'),
    },

  ],
})

export default router
