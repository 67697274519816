<template>
  <v-theme-provider :dark="dark">
    <div>
      <base-img
        :src="require('@/assets/cantdelimar-logo.png')"
        max-width="178"
        min-height="78"
        width="100%"
      />

      <base-title
        size="body-1"
        space="4"
        title="Cantdelimar"
        weight="regular"
      />

      <base-body>
        Cantdelimar es una empresa de Cantabria dedicada a la fabricación de conservas de pescado, principalmente pudin de pescado. Desde 1996, esta empresa elabora pastel de pescado de roca, txangurro y otros productos en conserva y semi conserva. La calidad de todos sus productos, el servicio a sus clientes y una elaboración artesana son las características que diferencian a Cantdelimar como una empresa sólida que apuesta por ofrecer un producto que destaque por su incomparable sabor y calidad, así como por una excelente presentación.
      </base-body>

      <base-btn
        class="mb-12"
        color="white"
        outlined
      >
        Más información
      </base-btn>
    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseInfo',

    props: {
      title: String,
      dark: Boolean,
    },

    data: () => ({
      business: [
        {
          icon: 'mdi-map-marker-outline',
          title: 'Dirección',
          text: 'POL.IND.DE CROSS, EDIFICIO 4 NAVE 6 39600 MALIAÑO (CANTABRIA) España',
        },
        {
          icon: 'mdi-cellphone',
          title: 'Teléfono',
          text: '942 26 96 44',
        },
        {
          icon: 'mdi-email',
          title: 'Email',
          text: 'cantdelimar@cantdelimar.es',
        },
      ],
    }),
  }
</script>
