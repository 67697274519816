<template>
  <v-theme-provider :dark="dark">
    <div>
      <base-info-card
        title="Mantén el contacto con nosotros"
        pace="6"
      >
        Solicite información sobre nuestros elaborados con el siguiente formulario.
      </base-info-card>

      <base-business-contact dense />
    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseBusinessInfo',

    props: { dark: Boolean },

    data: () => ({
      business: [
        {
          icon: 'mdi-map-marker-outline',
          title: 'Dirección',
          text: 'POL.IND.DE CROSS, EDIFICIO 4 NAVE 6 39600 MALIAÑO (CANTABRIA) España',
        },
        {
          icon: 'mdi-cellphone',
          title: 'Teléfono',
          text: '942 26 96 44',
        },
        {
          icon: 'mdi-email',
          title: 'Email',
          text: 'cantdelimar@cantdelimar.es',
        },
      ],
    }),
  }
</script>
