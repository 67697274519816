<template>
  <div>
    <base-info-card
      :title="title"
      :subtitle="subtitle"
      space="4"
      color="primary"
    />
    <base-text-field
      label="Contacto Cantdelimar Web"
      disabled
    />
    <base-text-field
      label="Nombre"
      v-model="nombre"
      required
    />
    <base-textarea
      class="mb-6"
      label="Su necesidad y descripción"
      v-model="message"
    />
    <base-btn
      :color="!theme.isDark ? 'accent' : 'white'"
      outlined
      target="_blank"
      @click="encoder()"
    >
      <a
        target="_blank"
        style="text-decoration:none;"
        :href="'mailto:cantdelimar@cantdelimar.es?subject=Contácto%20Cantdelimar%20Web&body=Buenos%20d%C3%ADas:%0AMi%20nombre%20es%20' + nombre + '%20y%20quiero%20comunicar%20lo%20siguiente.%0A'+ messageF"
      >
          Enviar Mensaje
      </a>
    </base-btn>
  </div>
</template>

<script>
  export default {
    name: 'BaseContactForm',
    data: () => ({
      nombre: '',
      message: '',
      messageF: '',
    }),
    methods: {
      encoder () {
        this.messageF = encodeURIComponent(this.message.trim())
      },
    },
    inject: ['theme'],
    props: {
      subtitle: String,
      title: {
        type: String,
        default: 'Evíanos por correo tu mensaje',
      },
    },
  }
</script>
